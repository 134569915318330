<template>
  <div class="welcome-digital-tco-container">
    <v-card flat class="welcome-message-card">
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="d-flex flex-column justify-center text-center text-sm-left h-100 primary--text"
          >
            <v-card-title
              v-html="$t('tco.becomeTco.welcomePageDigital.title')"
            ></v-card-title>
            <v-card-text class="primary--text">
              <p
                class="mr-md-4"
                v-html="$t('tco.becomeTco.welcomePageDigital.subtitle')"
              ></p>
              <p
                class="mr-md-4"
                v-html="$t('tco.becomeTco.welcomePageDigital.content')"
              ></p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :to="{ name: 'TcoPerks' }"
                color="primary"
                depressed
                :block="$vuetify.breakpoint.xs"
              >
                {{ $t("tco.becomeTco.welcomePageDigital.btn") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            height="100%"
            src="/img_layout/become_tco/card-phisic.png"
            alt="Immagine carta oro"
            contain
          >
            <span class="card-overlay-username"
              >{{ user.firstName }}&nbsp;{{ user.lastName }}</span
            >
            <span class="card-overlay-fidelity">{{ user.fidelityCard }}</span>
          </v-img>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style lang="scss">
.welcome-digital-tco-container {
  .welcome-message-card {
    background-image: linear-gradient(
      270deg,
      #ffd83a,
      rgba(245, 240, 197, 0.1576),
      rgba(254, 248, 205, 0.8356),
      #ffd83a
    );
    .v-card__title {
      font-size: 50px;
      line-height: 1em;
      margin-bottom: 30px;
      font-family: $heading-font-family;
    }
    .v-card__text {
      font-size: 20px;
    }
    .card-overlay-username {
      position: absolute;
      top: 23%;
      right: 17%;
      color: white;
      font-weight: bold;
    }
    .card-overlay-fidelity {
      position: absolute;
      top: 69%;
      right: 44%;
      color: white;
      font-weight: bold;
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      .card-overlay-username {
        top: 27%;
      }
      .card-overlay-fidelity {
        top: 66%;
      }
    }
    @media #{map-get($display-breakpoints, 'sm-only')} {
      .card-overlay-username {
        top: 27%;
      }
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "WelcomeDigitalTco",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {},
  mounted() {}
};
</script>
